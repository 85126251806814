.logo {
  color: rgb(0, 119, 182);
}

.site-layout-background {
  background: #f0f2f5;
}

.ant-card-meta-title {
  font-weight: bold;
}

.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}

.header-container {
  display: flex;
  align-items: center;
}

.icons {
  display: flex;
  align-items: center;
}

.ant-menu-horizontal {
  border: none;
}

.ant-card-body {
  padding: 0px !important;
}

.ant-menu-submenu-selected {
  color: #0077b6 !important;
}

.ant-menu-submenu-selected::after {
  background-color: #0077b6 !important;
}
